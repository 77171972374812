@use 'common'

@font-face
	font-family: common.$sangBleuSans
	src: url('/fonts/SangBleuSans-Regular.woff2')
	font-style: normal
	font-weight: 400
	font-display: swap

html
	overflow-y: scroll

html,
body
	color: #000000
	padding: 0
	margin: 0
	font-family: common.$bodyFont
	background-color: common.$bodyColor

h1, h2, h3, h4, h5
	font-family: common.$headingFont

a
	color: inherit
	text-decoration: none

button
	cursor: pointer

*
	box-sizing: border-box

:root
	scroll-behavior: smooth
	--page-horizontal-spacing: 1rem

	--content-width-normal: #{common.$contentWidth-normal}
	--content-width-narrow: 47.5rem
	--content-width-wide: 128rem

	--border-radius: 10px
